import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { TweenMax, TimelineLite, Power3 } from 'gsap'

import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'

const FirstPhotoBox = styled.div`
  border: 0.5rem solid var(--textColor);
  border-bottom: 2.5rem solid var(--textColor);
  position: absolute;
  width: 100%;
  max-width: 250px;
  outline: 2px solid var(--shadow);
  overflow: hidden;
  opacity: 0;
`

const SecondPhotoBox = styled.div`
  border: 0.5rem solid var(--textColor);
  border-bottom: 2.5rem solid var(--textColor);
  position: absolute;
  right: 0;
  width: 100%;
  max-width: 250px;
  min-height: 250px;
  min-width: 250px;
  outline: 2px solid var(--shadow);
  overflow: hidden;
`

const PhotoContainer = styled.div`
  /* border: 2px solid var(--shadow); */
`

const Travellers = styled.div`
  visibility: hidden;
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  height: 90vh;
`

const pageTitle = 'Travel'
function TravelPage() {
  let app = useRef(null)
  let images = useRef(null)

  const TL = new TimelineLite()

  useEffect(() => {
    TweenMax.to(app, 0, { css: { visibility: 'visible' } })
    const firstImage = images.firstElementChild
    // const secondImage = images.lastElementChild

    TL.from(firstImage, { y: -20, ease: Power3.easeOut, duration: 1.2 })
      .from(firstImage, { opacity: 1, ease: Power3.easeOut, duration: 0 })
      .from(firstImage, { rotate: 10, ease: Power3.easeOut, duration: 0 }, 0.2)
    // return () => {
    //   cleanup
    // }
  }, [])
  const imgUrl = 'https://picsum.photos/300'

  return (
    <>
      <FunkyTitle title={pageTitle} />

      <Travellers ref={el => (app = el)}>
        <PhotoContainer ref={el => (images = el)}>
          <FirstPhotoBox>
            <img src={imgUrl} />
          </FirstPhotoBox>
          <SecondPhotoBox>
            <img src={imgUrl} />
          </SecondPhotoBox>
        </PhotoContainer>
      </Travellers>

      {/* images and small snippets from all out travels */}
      {/* inspo - https://codepen.io/kathykato/live/MqYVOq */}
      {/* http://www.robertfikesiv.com/ */}
      {/* https://codepen.io/ReGGae/pen/povjKxV */}
      {/* interactive map */}
      {/* https://notanomadblog.com/ */}
    </>
  )
}

TravelPage.Layout = Layout
TravelPage.pageTitle = pageTitle

export default TravelPage
